import React from 'react';
import { Helmet } from 'react-helmet';
import { FaThumbsUp, FaUserCheck, FaStar } from 'react-icons/fa';
import '../scss/AboutCompany.scss';

export function AboutCompany() {
  return (
    <section className="about-company" aria-label="О нашей компании">
      <Helmet>
        <title>О нашей компании - Клининговая компания</title>
        <meta name="description" content="Мы предлагаем высококачественные клининговые услуги с индивидуальным подходом." />
      </Helmet>

      <h2 className="about-company__title">О нашей компании</h2>
      <p className="about-company__description">
        Мы предоставляем высококачественные клининговые услуги, стремясь создать чистое и 
        комфортное пространство для вашего дома или офиса. Используем экологически чистые средства 
        и современные технологии для достижения наилучшего результата.
      </p>
      <p className="about-company__additional-info">
        Клининговая компания предлагает уборку квартир, коттеджей, офисов и коммерческих помещений, 
        и гарантирует высокое качество услуг. К каждому клиенту у нас индивидуальный подход, 
        а гибкое ценообразование позволит подобрать подходящий пакет услуг.
      </p>
      <div className="about-company__values">
        <div className="value-item" role="region" aria-labelledby="value-reliability">
          <FaThumbsUp className="value-icon" aria-hidden="true" />
          <h3 id="value-reliability" className="value-title">Надежность</h3>
          <p className="value-description">Гарантируем выполнение работ в срок и с высоким качеством.</p>
        </div>
        <div className="value-item" role="region" aria-labelledby="value-approach">
          <FaUserCheck className="value-icon" aria-hidden="true" />
          <h3 id="value-approach" className="value-title">Индивидуальный подход</h3>
          <p className="value-description">Учитываем все ваши пожелания и требования.</p>
        </div>
        <div className="value-item" role="region" aria-labelledby="value-professionalism">
          <FaStar className="value-icon" aria-hidden="true" />
          <h3 id="value-professionalism" className="value-title">Профессионализм</h3>
          <p className="value-description">Наша команда — это квалифицированные специалисты.</p>
        </div>
      </div>
    </section>
  );
}
