import React from 'react';
import { AboutHeader } from '../components/AboutHeader';
import { AboutCompany } from '../components/AboutCompany';
import { AboutValues } from '../components/AboutValues';
import '../scss/AboutPage.scss'

export function AboutPage() {
  return (
    <div className="about-page">
      <AboutHeader />
      <AboutCompany />
      <AboutValues />
    </div>
  );
}
