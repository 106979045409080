import React from 'react';
import {HeroSection} from '../components/HeroSection';
import {ServicesSection} from '../components/ServicesSection';

import '../scss/HomePage.scss'; // Импортируем стили для главной страницы

export function HomePage() {
  return (
    <div className="home-page">
      <HeroSection />
      <ServicesSection />
    </div>
  );
}
