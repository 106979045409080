import React from 'react';
import { FaRubleSign } from 'react-icons/fa'; // Импортируем иконку рубля
import '../scss/PriceList.scss';

export function PriceList() {
  const cleaningRates = [
    { type: 'Регулярная уборка', price: 50 },
    { type: 'Генеральная уборка', price: 100 },
    { type: 'Послестроительная уборка', price: 125 },
  ];

  const additionalServices = [
    { service: 'Диван (место)', price: 500 },
    { service: 'Кресло', price: 500 },
    { service: 'Матрас', price: 1000 },
    { service: 'Стул', price: 100 },
    { service: 'Мойка стандартного окна', price: 250 },
    { service: 'Мойка витражного окна', price: 500 },
    { service: 'Мойка стандартного балкона', price: 1500 },
    { service: 'Мойка витражного балкона', price: 2500 },
    { service: 'Глажка', price: 500 },
    { service: 'Мойка духовки изнутри', price: 600 },
    { service: 'Мойка холодильника изнутри', price: 600 },
    { service: 'Мойка микроволновки изнутри', price: 600 },
  ];

  return (
    <section className="price-list">
      <h2>Прайс-лист</h2>
      <h3>Расценки на уборку домов</h3>
      <table>
        <thead>
          <tr>
            <th>Тип уборки</th>
            <th>Цена</th>
          </tr>
        </thead>
        <tbody>
          {cleaningRates.map((rate, index) => (
            <tr key={index}>
              <td>{rate.type}</td>
              <td>
                <FaRubleSign /> {rate.price} /м²
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h3>Дополнительные услуги</h3>
      <table>
        <thead>
          <tr>
            <th>Услуга</th>
            <th>Цена</th>
          </tr>
        </thead>
        <tbody>
          {additionalServices.map((service, index) => (
            <tr key={index}>
              <td>{service.service}</td>
              <td>
                <FaRubleSign /> {service.price}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}
