import React from 'react';
import { Helmet } from 'react-helmet'; // Импортируем Helmet для SEO
import { FaUsers, FaClipboardCheck } from 'react-icons/fa';
import '../scss/AboutHeader.scss';

export function AboutHeader() {
  return (
    <header className="about-header">
      <Helmet>
        <title>О нас - Клининговая компания CleanMeUp</title>
        <meta name="description" content="Мы команда профессионалов в области клининга, стремящаяся создать чистое и комфортное пространство." />
      </Helmet>
      <div className="about-header__content">
        <h1 className="about-header__title">О нас</h1>
        <p className="about-header__description">
          Мы — команда профессионалов в области клининга, стремящихся создать чистое и 
          комфортное пространство для вашего дома или офиса. Наша миссия — предоставлять 
          высококачественные услуги.
        </p>
        <p className="about-header__additional-info">
          Проверенные сотрудники справятся с уборкой любой сложности. Вы сможете оценить результат и только потом оплатите услуги.
        </p>
        <p className="about-header__quote">"Мы создаем чистоту, чтобы вы могли наслаждаться жизнью!"</p>
        
        <div className="about-header__icons">
          <div className="icon">
            <FaUsers size={40} />
            <p>Профессиональная команда</p>
          </div>
          <div className="icon">
            <FaClipboardCheck size={40} />
            <p>Качество на первом месте</p>
          </div>
        </div>
      </div>
    </header>
  );
}
