// src/App.jsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {AboutPage} from './pages/AboutPage'; // Импортируем страницы
import {ServicesPage} from './pages/ServicesPage';
import {ContactPage} from './pages/ContactPage';
import { Layout } from './components/Layout'; // Импортируем Layout
import {HomePage} from './pages/HomePage'; // Измените на дефолтный импорт

export const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}> {/* Главный layout для сайта */}
        <Route index element={<HomePage />} /> {/* Главная страница */}
        <Route path="/about" element={<AboutPage />} /> {/* Страница "О нас" */}
        <Route path="/services" element={<ServicesPage />} /> {/* Страница с услугами */}
        <Route path="/contact" element={<ContactPage />} /> {/* Страница контактов */}
      </Route>
    </Routes>
  );
};
