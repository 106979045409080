import React from 'react';
import { ContactHeader } from '../components/ContactHeader';
import { ContactInfo } from '../components/ContactInfo';
import '../scss/ContactPage.scss';

export function ContactPage() {
  return (
    <div className="contact-page">
      <ContactHeader />
      <ContactInfo />
    </div>
  );
}
