import React from 'react';
import PropTypes from 'prop-types';
import '../scss/Popup.scss'; // Импортируем стили для всплывающего окна

export const Popup = ({ title, onClose, children }) => {
    return (
      <div className="popup">
        <div className="popup__content">
          <h2>{title}</h2>
          {children} {/* Отображаем содержимое (формы) */}
          <button className="popup__close-button" onClick={onClose}>Закрыть</button>
        </div>
      </div>
    );
  };
  
  Popup.propTypes = {
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
  };


