import React, { useState } from 'react';
import '../scss/CleaningCostCalculator.scss'; // Импорт стилей для вашего компонента

const pricing = {
  regular: 50, // Стоимость регулярной уборки за м²
  general: 100, // Стоимость генеральной уборки за м²
  postConstruction: 125, // Стоимость послестроительной уборки за м²
};

export function CleaningCostCalculator() {
  const [area, setArea] = useState('');
  const [cleaningType, setCleaningType] = useState('regular');
  const [cost, setCost] = useState(null);

  const calculateCost = () => {
    if (!area) {
      alert('Пожалуйста, введите площадь!');
      return;
    }

    const pricePerM2 = pricing[cleaningType];
    const totalCost = pricePerM2 * area;
    setCost(totalCost);
  };

  return (
    <div className="cleaning-cost-calculator">
      <h2>Калькулятор стоимости уборки</h2>
      <div className="calculator-inputs">
        <label>
          Площадь (в м²):
          <input
            type="number"
            value={area}
            onChange={(e) => setArea(e.target.value)}
            placeholder="Введите площадь"
            min="1"
          />
        </label>
        <label>
          Тип уборки:
          <select value={cleaningType} onChange={(e) => setCleaningType(e.target.value)}>
            <option value="regular">Регулярная уборка</option>
            <option value="general">Генеральная уборка</option>
            <option value="postConstruction">Послестроительная уборка</option>
          </select>
        </label>
        <button onClick={calculateCost}>Рассчитать</button>
      </div>
      {cost !== null && (
        <div className="calculator-result">
          <h3>Предполагаемая стоимость: {cost} ₽</h3>
        </div>
      )}
    </div>
  );
}
