import React, { useState } from 'react';
import '../scss/ConsultationForm.scss'; // Импортируем стили

export const ConsultationForm = ({ onClose }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [question, setQuestion] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlePhoneChange = (e) => {
    // Получаем только цифры из ввода
    const value = e.target.value.replace(/\D/g, '');
    
    // Применяем формат для российского номера (например, +7 (123) 456-78-90)
    let formattedPhone = value;
    
    if (formattedPhone.length > 1) {
      formattedPhone = `+7 (${formattedPhone.slice(1, 4)}) ${formattedPhone.slice(4, 7)}-${formattedPhone.slice(7, 9)}-${formattedPhone.slice(9, 11)}`;
    }

    setPhone(formattedPhone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const response = await fetch('https://api.cleanmeup.ru:8443/send-consultation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        name,
        phone,
        question,
      }),
    });

    if (response.ok) {
      alert('Ваш запрос отправлен!');
      setName('');
      setPhone('');
      setQuestion('');
      onClose();
    } else {
      const errorData = await response.json();
      alert(`Ошибка при отправке запроса: ${errorData.message || 'Неизвестная ошибка.'}`);
    }

    setIsSubmitting(false);
  };

  return (
    <form className="consultation-form" onSubmit={handleSubmit} acceptCharset="UTF-8">
      <h2 className="consultation-form__title">Получить консультацию</h2>

      <label className="consultation-form__label">
        Ваше имя:
        <input
          type="text"
          className="consultation-form__input"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </label>

      <label className="consultation-form__label">
        Номер телефона:
        <input
          type="tel"
          className="consultation-form__input"
          required
          value={phone}
          onChange={handlePhoneChange} // Обработка ввода телефона
          maxLength="18" // Ограничиваем длину ввода до 18 символов
        />
      </label>

      <label className="consultation-form__label">
        Ваш вопрос:
        <textarea
          className="consultation-form__textarea"
          required
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        ></textarea>
      </label>

      <div className="consultation-form__buttons">
        <button
          type="submit"
          className="consultation-form__submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Отправка...' : 'Отправить запрос'}
        </button>
      </div>
    </form>
  );
};
