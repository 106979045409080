import React from 'react';
import '../scss/ContactInfo.scss';

export function ContactInfo() {
  return (
    <div className="contact-info">
      <h2>Контактная информация</h2>
      <div className="contact-info__details">
        <div className="contact-info__item">
          <span className="contact-info__icon" role="img" aria-label="phone">📞</span>
          <div>
            <p className="contact-info__title">Телефон:</p>
            <p className="contact-info__phone">+7 (995) 996-24-54</p>
          </div>
        </div>
        <div className="contact-info__item">
          <span className="contact-info__icon" role="img" aria-label="address">🏠</span>
          <div>
            <p className="contact-info__title">Адрес:</p>
            <p className="contact-info__address">Санкт-Петербург, Проспект Славы 4</p>
          </div>
        </div>
      </div>
      <div className="contact-info__additional">
        <h3>Наши часы работы</h3>
        <p>Пн - Пт: 08:00 - 22:00</p>
        <p>Сб - Вс: 09:00 - 21:00</p>
      </div>
      
    </div>
  );
}
