import React from 'react';
import '../scss/ServiceIntro.scss';

export function ServiceIntro() {
  return (
    <section className="service-intro">
      <div className="content">
        <h1>Профессиональные клининговые услуги</h1>
        <p>Чистота и порядок в вашем доме или офисе — это наш приоритет. Мы обеспечиваем высокий уровень качества и заботы.</p>
        <p>
          Доступны разные типы уборки — поддерживающая, генеральная, после ремонта и выборочная. 
          Можно заказать услугу разово или по графику. Для этого достаточно оставить заявку на сайте 
          или по телефону.
        </p>
        <p className="discount">
          При первом заказе — <strong>скидка 15%</strong>. Заключив договор, компания несет материальную 
          ответственность за сохранность имущества клиента и дает гарантию на 24 часа.
        </p>
      </div>
    </section>
  );
}
