import React, { useState } from 'react';
import { Helmet } from 'react-helmet'; // Импортируем Helmet
import '../scss/ServicesSection.scss'; // Импортируем стили

export const ServicesSection = () => {
  // Пример данных услуг
  const services = [
    {
      title: 'Уборка квартир',
      description: 'Полная уборка вашего жилья, включая уборку после ремонта.',
      icon: '🏠', // Замените на иконку из react-icons
    },
    {
      title: 'Уборка офисов',
      description: 'Создание комфортной рабочей атмосферы с помощью профессиональной уборки.',
      icon: '🏢',
    },
    {
      title: 'Генеральная уборка',
      description: 'Качественная уборка с глубокой очисткой всех поверхностей.',
      icon: '🧹',
    },
    {
      title: 'Мытье окон',
      description: 'Чистка окон для лучшего освещения и эстетического вида.',
      icon: '🪟',
    },
  ];

  // Дополнительные услуги
  const additionalServices = [
    {
      title: 'Регулярная уборка',
      description: 'Сухая и влажная уборка всего помещения, необходимая раз в 1-2 недели.',
      icon: '🧼', // Иконка для регулярной уборки
    },
    {
      title: 'Генеральная уборка',
      description: 'Комплексная уборка квартиры, включая мойку окон и балконов.',
      icon: '🧹', // Иконка для генеральной уборки
    },
    {
      title: 'Послестроительная уборка',
      description: 'Единоразовая услуга после ремонта, направленная на избавление от строительных смесей.',
      icon: '🔨', // Иконка для послестроительной уборки
    },
    {
      title: 'Химчистка мягкой мебели',
      description: 'Профессиональная чистка мебели с использованием специального пылесоса.',
      icon: '🛋️', // Иконка для химчистки мебели
    },
    {
      title: 'Глажка белья',
      description: 'Глажка белья на дому с использованием оборудования компании.',
      icon: '🧺', // Иконка для глажки белья
    },
   
  ];

  const [showAdditional, setShowAdditional] = useState(false);

  return (
    <section className="services" aria-label="Наши услуги">
      <Helmet>
        <title>Наши услуги - Клининговая компания CleanMeUp</title>
        <meta name="description" content="Мы предлагаем широкий спектр клининговых услуг для вашего дома и офиса." />
      </Helmet>
      
      <h2 className="services__title">Наши услуги</h2>
      <p className="services__description">
        Мы предлагаем широкий спектр клининговых услуг для вашего дома и офиса.
      </p>
      <div className="services__list">
        {services.map((service, index) => (
          <div key={index} className="service" role="region" aria-labelledby={`service-title-${index}`}>
            <div className="service__icon" aria-hidden="true">{service.icon}</div>
            <h3 id={`service-title-${index}`} className="service__title">{service.title}</h3>
            <p className="service__description">{service.description}</p>
          </div>
        ))}
      </div>

      <button 
        className="services__additional-button" 
        onClick={() => setShowAdditional(!showAdditional)} 
        aria-expanded={showAdditional} 
        aria-controls="additional-services"
      >
        {showAdditional ? 'Скрыть дополнительные услуги' : 'Показать дополнительные услуги'}
      </button>

      {showAdditional && (
        <div id="additional-services" className="services__additional-list">
          {additionalServices.map((service, index) => (
            <div key={index} className="service" role="region" aria-labelledby={`additional-service-title-${index}`}>
              <div className="service__icon" aria-hidden="true">{service.icon}</div>
              <h4 id={`additional-service-title-${index}`} className="service__title">{service.title}</h4>
              <p className="service__description">{service.description}</p>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};
