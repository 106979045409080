import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import '../scss/HeroSection.scss'; // Импортируем стили
import {Popup} from './Popup'; // Импортируем компонент всплывающего окна
import {OrderForm} from './OrderForm'; // Импортируем форму заказа уборки
import {ConsultationForm} from './ConsultationForm'; // Импортируем форму консультации

export const HeroSection = () => {
  const [isOrderPopupOpen, setOrderPopupOpen] = useState(false);
  const [isConsultationPopupOpen, setConsultationPopupOpen] = useState(false);

  const handleOrderClick = () => {
    setOrderPopupOpen(true);
  };

  const handleConsultationClick = () => {
    setConsultationPopupOpen(true);
  };

  const closeOrderPopup = () => {
    setOrderPopupOpen(false);
  };

  const closeConsultationPopup = () => {
    setConsultationPopupOpen(false);
  };

  return (
    <section className="hero" aria-label="Добро пожаловать в нашу клининговую компанию">
      <Helmet>
        <meta name="description" content="Профессиональные услуги клининга для вашего дома и офиса. Надежный партнер в обеспечении чистоты." />
        <link rel="canonical" href="/" />
      </Helmet>
      <img
        className="background-image"
        src="/images/bg.webp"
        alt="Чистота и порядок в вашем доме и офисе" // Более информативный alt текст
      />
      <div className="hero__content">
        <div className="content-container"> {/* Новый контейнер для текста */}
          <h1 className="hero__title">Ваш надежный партнер в чистоте</h1>
          <p className="hero__description">
            Профессиональные услуги клининга для вашего дома и офиса.
          </p>
          <div className="hero__buttons">
            <button className="hero__cta-button" aria-label="Заказать уборку" onClick={handleOrderClick}>
              Заказать уборку
            </button>
            <button className="hero__cta-button hero__cta-button--consultation" aria-label="Получить консультацию" onClick={handleConsultationClick}>
              Получить консультацию
            </button>
          </div>
        </div>
      </div>
      
      {/* Всплывающие окна */}
      {isOrderPopupOpen && (
        <Popup 
          title="Заказ уборки" 
          onClose={closeOrderPopup} 
        >
          <OrderForm onClose={closeOrderPopup} />
        </Popup>
      )}
      {isConsultationPopupOpen && (
        <Popup 
          title="Консультация" 
          onClose={closeConsultationPopup} 
        >
          <ConsultationForm onClose={closeConsultationPopup} />
        </Popup>
      )}
    </section>
  );
};
