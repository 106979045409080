// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'; // Импортируем BrowserRouter
import './index.scss'; // Убедитесь, что импортируете правильный файл стилей
import {App} from './App'; // Или { App } если используете именованный экспорт

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter> {/* Оборачиваем приложение в BrowserRouter */}
    <App />
  </BrowserRouter>
);
