import React from 'react';
import { ServiceIntro } from '../components/ServiceIntro';
import { ServiceList } from '../components/ServiceList';
import { Advantages } from '../components/Advantages';
import { PriceList } from '../components/PriceList';
import { CleaningCostCalculator } from '../components/CleaningCostCalculator';


export function ServicesPage() {
  return (
    <div>
      <ServiceIntro />
      <PriceList />
      <CleaningCostCalculator />
      <ServiceList />
      <Advantages />
    </div>
  );
}
