export function ContactHeader() {
    return (
      <header className="contact-header">
        <h1>Свяжитесь с нами</h1>
        <p>Мы всегда рады помочь вам! Оставьте заявку, и наш специалист свяжется с вами в кратчайшие сроки.</p>
        <p>Вам нужна консультация или помощь? Мы предоставляем широкий спектр услуг и гарантируем качество!</p>
        <p>Не ждите, свяжитесь с нами сегодня и получите свою скидку на первый заказ!</p>
      </header>
    );
  }
  