import React from 'react';
import { Helmet } from 'react-helmet';
import { FaUsers, FaClipboardCheck, FaHeart } from 'react-icons/fa';
import '../scss/AboutValues.scss'; // Импорт стилей

export function AboutValues() {
  return (
    <section className="about-values">
      <Helmet>
        <title>Ценности нашей компании</title>
        <meta name="description" content="Наша компания придерживается высоких стандартов и ценностей, основанных на доверии и качестве." />
      </Helmet>
      <h2 className="about-values__title">Наши Ценности</h2>
      <p className="about-values__description">
        В основе нашей работы лежат принципы, которые определяют нашу идентичность и подход к клиентам. Мы верим в:
      </p>

      <div className="about-values__items">
        <div className="value-item">
          <div className="value-item__icon"><FaUsers /></div>
          <h3 className="value-item__title">Командное взаимодействие</h3>
          <p className="value-item__description">
            Наша команда — это сплоченный коллектив профессионалов, который эффективно взаимодействует, чтобы обеспечить высочайший уровень сервиса.
          </p>
        </div>
        <div className="value-item">
          <div className="value-item__icon"><FaClipboardCheck /></div>
          <h3 className="value-item__title">Приверженность качеству</h3>
          <p className="value-item__description">
            Мы строго следим за качеством нашей работы и стремимся к постоянному улучшению. Каждый проект — это шаг к совершенству.
          </p>
        </div>
        <div className="value-item">
          <div className="value-item__icon"><FaHeart /></div>
          <h3 className="value-item__title">Забота о клиентах</h3>
          <p className="value-item__description">
            Мы ценим каждого клиента и стараемся создать атмосферу доверия, где ваши потребности становятся нашими приоритетами.
          </p>
        </div>
      </div>
    </section>
  );
}
