// src/components/Layout.jsx
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from './Header'; // Импортируем Header
import { Footer } from './Footer';


export const Layout = () => {
  return (
    <div>
      <Header /> {/* Общий заголовок для всех страниц */}
      <main>
        <Outlet /> {/* Здесь будут рендериться страницы */}
      </main>
      <Footer />
    </div>
  );
};
