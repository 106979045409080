// В файле Header.jsx
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaHome, FaUser, FaBriefcase, FaEnvelope, FaTimes } from 'react-icons/fa'; // Импортируем иконки
import { GiHamburgerMenu } from 'react-icons/gi'; // Импортируем иконку гамбургера
import '../scss/Header.scss'; // Импортируем стили

export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Состояние для открытия/закрытия меню

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header" role="banner">
      <div className="header__container">
        <div className="header__logo">
          <NavLink to="/" aria-label="Главная страница" title="Перейти на главную страницу">
          CleanMeUp
          </NavLink>
        </div>
        <div className="header__menu-toggle" onClick={toggleMenu} aria-label="Открыть/закрыть меню">
          {isMenuOpen ? <FaTimes className="menu-icon" /> : <GiHamburgerMenu className="menu-icon" />}
        </div>
        <nav className={`header__nav ${isMenuOpen ? 'open' : ''}`} aria-label="Основное меню навигации">
          <ul className="header__nav-list">
            <li className="header__nav-item">
              <NavLink to="/" className="header__nav-link" activeClassName="active" aria-label="Главная" title="Перейти на главную страницу">
                <FaHome className="nav-icon" /> Главная
              </NavLink>
            </li>
            <li className="header__nav-item">
              <NavLink to="/about" className="header__nav-link" activeClassName="active" aria-label="О компании" title="Узнать о нашей компании">
                <FaUser className="nav-icon" /> О компании
              </NavLink>
            </li>
            <li className="header__nav-item">
              <NavLink to="/services" className="header__nav-link" activeClassName="active" aria-label="Услуги" title="Посмотреть наши услуги">
                <FaBriefcase className="nav-icon" /> Услуги
              </NavLink>
            </li>
            <li className="header__nav-item">
              <NavLink to="/contact" className="header__nav-link" activeClassName="active" aria-label="Контакты" title="Связаться с нами">
                <FaEnvelope className="nav-icon" /> Контакты
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};
