import React from 'react';
import { Link } from 'react-router-dom';
import '../scss/Footer.scss';

export function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="social-icons">
          <ul>
            <li>
              <a href="https://t.me/am_servise" target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-telegram"></i>
              </a>
            </li>
          </ul>
        </div>
        
        <nav className="footer-menu">
          <ul>
            <li>
              <Link to="/">Главная</Link>
            </li>
            <li>
              <Link to="/about">О нас</Link>
            </li>
            <li>
              <Link to="/contact">Контакты</Link>
            </li>
            <li>
              <Link to="/services">Услуги</Link>
            </li>
          </ul>
        </nav>
        
        <div className="footer-info">
          <p><i className="fa-solid fa-map-marker-alt"></i> Проспект славы 4</p>
          <p><i className="fa-solid fa-phone"></i> +7-995-996-24-54</p>
          {/* <p><i className="fa-solid fa-registered"></i> ОГРН: 324784700015025</p>
          <p><i className="fa-solid fa-id-card"></i> ИНН: 780445524880</p> */}
        </div>
      </div>
      <p className="footer-text">&copy; 2024 CleanMeUp | Все права защищены</p>
    </footer>
  );
}
