import React, { useState } from 'react';
import '../scss/OrderForm.scss';

export const OrderForm = () => {
  const [selectedServices, setSelectedServices] = useState([]);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [details, setDetails] = useState('');
  const [isServicesOpen, setIsServicesOpen] = useState(false);

  const services = [
    'Уборка квартир', 'Уборка офисов', 'Генеральная уборка',
    'Уборка после ремонта', 'Мытье окон', 'Химчистка ковров',
    'Химчистка мебели', 'Уборка после вечеринки',
    'Уборка подъездов', 'Поддерживающая уборка',
  ];

  const handleServiceClick = (service) => {
    setSelectedServices((prevSelected) =>
      prevSelected.includes(service)
        ? prevSelected.filter((s) => s !== service)
        : [...prevSelected, service]
    );
  };

  const toggleServices = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value.replace(/\D/g, ''); // Убираем всё, кроме цифр
    let formattedPhone = input;

    if (formattedPhone.length > 0) {
      formattedPhone = `+7 (${formattedPhone.slice(1, 4)}) ${formattedPhone.slice(4, 7)}-${formattedPhone.slice(7, 9)}-${formattedPhone.slice(9, 11)}`;
    }

    setPhone(formattedPhone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.cleanmeup.ru:8443/send-order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8', // Указываем кодировку UTF-8
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          name,
          phone: phone.replace(/\D/g, ''),  // Убираем все нецифровые символы из номера
          address,
          services: selectedServices,
          details: details || '', // Передаем как строку, без кодирования
        }),
      });

      const text = await response.text(); // Считываем ответ как текст
      console.log('Ответ сервера:', text);

      if (response.ok) {
        alert('Ваш запрос отправлен!');
        setName('');
        setPhone('');
        setAddress('');
        setDetails('');
        setSelectedServices([]);
        setIsServicesOpen(false);
      } else {
        console.error('Ошибка сервера:', text);
        alert(`Ошибка при отправке запроса: ${text}`);
      }
    } catch (error) {
      alert('Произошла ошибка при отправке запроса.');
      console.error(error);
    }
  };

  return (
    <form className="order-cleaning-form" onSubmit={handleSubmit}>
      <label htmlFor="name">
        Ваше имя:
        <input
          type="text"
          id="name"
          required
          placeholder="Введите ваше имя"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </label>

      <label htmlFor="phone">
        Номер телефона:
        <input
          type="tel"
          id="phone"
          required
          placeholder="+7 (___) ___-__-__"
          value={phone}
          onChange={handlePhoneChange}
          maxLength="18" // Ограничение по длине
        />
      </label>

      <label htmlFor="address">
        Адрес уборки:
        <input
          type="text"
          id="address"
          required
          placeholder="Введите адрес уборки"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
      </label>

      <div className="service-selection-toggle">
        <button type="button" onClick={toggleServices}>
          {isServicesOpen ? 'Скрыть услуги' : 'Выбрать услуги'}
        </button>
      </div>

      {isServicesOpen && (
        <div className="service-selection">
          <div className="service-list">
            {services.map((service) => (
              <div
                key={service}
                className={`service-card ${selectedServices.includes(service) ? 'selected' : ''}`}
                onClick={() => handleServiceClick(service)}
              >
                {service}
              </div>
            ))}
          </div>
        </div>
      )}

      <label htmlFor="details">
        Дополнительные детали (необязательно):
        <textarea
          id="details"
          rows="4"
          placeholder="Введите детали уборки (опционально)"
          value={details}
          onChange={(e) => setDetails(e.target.value)}
        ></textarea>
      </label>

      <button type="submit" className="submit-button">
        Отправить запрос
      </button>
    </form>
  );
};
