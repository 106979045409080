import React from 'react';
import { FaStar, FaThumbsUp, FaShieldAlt } from 'react-icons/fa';
import '../scss/Advantages.scss';

export function Advantages() {
  const advantages = [
    {
      icon: <FaStar className="advantage-icon" />,
      title: 'Качество',
      description: 'Мы гарантируем высочайшее качество уборки.',
    },
    {
      icon: <FaThumbsUp className="advantage-icon" />,
      title: 'Надежность',
      description: 'Только проверенные сотрудники.',
    },
    {
      icon: <FaShieldAlt className="advantage-icon" />,
      title: 'Безопасность',
      description: 'Безопасные для здоровья средства.',
    },
  ];

  return (
    <section className="advantages">
      <h2>Почему выбирают нас</h2>
      <ul>
        {advantages.map((adv, index) => (
          <li key={index} className="advantage-item">
            {adv.icon}
            <h3>{adv.title}</h3>
            <p>{adv.description}</p>
          </li>
        ))}
      </ul>
    </section>
  );
}
