import React from 'react';
import { FaRegCheckCircle, FaHome, FaBuilding, FaWindowRestore, FaSprayCan, FaTshirt, FaKey, FaTools } from 'react-icons/fa';
import '../scss/ServiceList.scss';

export function ServiceList() {
  const services = [
    {
      title: 'Регулярная уборка',
      description:
        'Сухая и влажная уборка всего помещения. Необходима раз в 1-2 недели и включает обеспыливание и влажную очистку всех поверхностей.',
      icon: <FaHome />
    },
    {
      title: 'Генеральная уборка',
      description:
        'Комплексная уборка квартиры, включая мойку окон и кухонной техники изнутри. Требуется 1 раз в месяц.',
      icon: <FaBuilding />
    },
    {
      title: 'Послестроительная уборка',
      description:
        'Единоразовая услуга после ремонта. Убирает следы строительных смесей, клея и известкового налета.',
      icon: <FaTools />
    },
    {
      title: 'Химчистка мягкой мебели',
      description:
        'Профессиональная чистка мебели (матрасов, стульев, диванов) с использованием специального пылесоса. 1-2 раза в год.',
      icon: <FaSprayCan />
    },
    {
      title: 'Глажка белья',
      description:
        'Производится на дому у заказчика с использованием оборудования и гладильной доски компании. Оплата почасовая.',
      icon: <FaTshirt />
    },

    {
      title: 'Отдельные услуги',
      description:
        'Например, мойка окон. Могут быть заказаны отдельно или с сумме с регулярной уборкой.',
      icon: <FaWindowRestore />
    },
  ];

  return (
    <section className="service-list">
      <h2>Доступные услуги</h2>
      <div className="service-cards">
        {services.map((service, index) => (
          <div key={index} className="service-card">
            <div className="service-icon">{service.icon}</div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
